import { useEffect, useRef } from "react";
import { About } from "./module/about/about";
import { Footer } from "./module/footer/footer";
import { Header } from "./module/header/header";
import { Cool } from "./module/cool/cool";
import { Welcome } from "./module/welcome/welcome";
import { Experience } from "./module/experience/experience";
import { Loading } from "./module/loading/loading";
import { Contact } from "./module/contact/contact";

function App() {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updateMousePosition = (ev: any) => {
      if (!ref.current) return;
      ref.current.style.transform = `translate(${ev.clientX}px,${
        ev.clientY + window.scrollY
      }px)`;
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  return (
    <>
      <div className="pointer" ref={ref} />
      <main className="main" id="main">
        <Header />
        <Welcome />
        <About />
        <Experience />
        <Cool />
        <Footer />
        <div className="dots" />
      </main>
      <Loading />
    </>
  );
}

export default App;
