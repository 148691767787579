import { useEffect } from "react";
import style from "./style/style.module.css";
import { Model } from "../computer/Model";

export function Loading() {
  // function handleScroll() {
  //   const content = window.document.getElementById(style.container);
  //   if (!content) return;

  //   const scrollY = window.scrollY;
  //   const blur = 1 + scrollY / 100;
  //   content.style.setProperty("--blur", `${blur}px`);
  // }

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div className={style.container} id={style.container}>
      <video
        muted
        autoPlay
        playsInline
        style={{ height: "100vh" }}
        src="/video/welcome_video.webm"
      />
    </div>
  );
}
