import style from "./style/style.module.css";

export function Welcome() {
  // function handleScroll() {
  //   const content = window.document.getElementById(style.content);
  //   if (!content) return;

  //   const scrollY = window.scrollY;
  //   const newScale = 1 + scrollY / 3000;
  //   const opacity = 1 - scrollY / 500;
  //   content.style.setProperty("--scale", `${newScale}`);
  //   // content.style.setProperty("--opacity", `${opacity}`);
  // }

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <section className={style.container} id="home">
        <div className={style.content}>
          <div className={style.text_content}>
            <h1 className={style.main_content}>
              Innovating with modern development and design.
            </h1>
            <div>
              <span className={style.info}>
                Empowering your digital journey with expertise in modern
                development, design, and cloud solutions.
              </span>
            </div>
          </div>
          <div className={style.cards}>
            <div className={style.card}></div>
            <div className={style.card}></div>
          </div>
        </div>
      </section>
    </>
  );
}
