import { useEffect } from "react";
import style from "./style/style.module.css";

export function About() {
  function handleScroll() {
    const content = window.document.getElementById("about");
    if (!content) return;

    const scrollY = window.scrollY;
    const newScale = 1 + scrollY / 3000;
    const opacity = 1 - scrollY / 500;
    // content.style.filter = `blur(${newScale}px)`;
    // content.style.setProperty("--opacity", `${opacity}`);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="about" className={style.container}>
      <div className={style.content}>
        <img src="/img/frame1.png" alt="" />
        <img src="/img/frame2.png" alt="" />
        <img src="/img/frame3.png" alt="" />
      </div>
    </section>
  );
}
