import { useGLTF } from "@react-three/drei";

export function Earth() {
  const { scene } = useGLTF("./models/stylized_planet.glb");

  return (
    <mesh  scale={0.75}>
    <primitive object={scene} />
    </mesh>
    );
}
