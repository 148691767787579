import style from "./style/style.module.css";

export function Experience() {
  return (
    <section id="experience" className={style.container}>
      <div className={style.sticky_shadow}>
        <div
          style={{
            right: 0,
            zIndex: 1,
            left: "-100vw",
            height: "30vh",
            width: "200vw",
            filter: "blur(12px)",
            position: "absolute",
            background: "linear-gradient(#000 80%,transparent 100%)",
          }}
        ></div>
      </div>
      <div className={style.content}>
        <div className={style.sticky}>
          <div>
            <h1 style={{ fontSize: "64px", zIndex: 2 }}>Experience</h1>
            <span className={style.info}>
              Empowering your digital journey with expertise in modern
              development, design, and cloud solutions.
            </span>
          </div>
        </div>

        <div className={style.items}>
          <div className={style.item} id="exp-item1" data-active={false}>
            <div className={style.header}>
              <img
                alt="net core"
                src="/img/csharp.svg"
                className={style.icon}
              />
              <h2>Software Development</h2>
            </div>
            <p className={style.p}>
              Experienced in building real-time applications with C# and .NET
              Core SignalR, leveraging robust database solutions for scalable
              and responsive software development.
            </p>
            <div className={style.languages}>
              <p className={style.language}>.Net Core</p>
              <p className={style.language}>MS Sql</p>
              <p className={style.language}>SignalR</p>
              <p className={style.language}>PubSub</p>
            </div>
          </div>

          <div className={style.item} id="exp-item2" data-active={false}>
            <div className={style.header}>
              <img alt="net core" src="/img/azure.svg" className={style.icon} />
              <h1>Azure Expertise</h1>
            </div>
            <p className={style.p}>
              Proficient in managing CI/CD pipelines using Azure DevOps,
              streamlining build and deployment processes for consistent and
              reliable application delivery. Experienced in utilizing Azure
              Portal for resource management, monitoring, and scaling
              cloud-based solutions to meet dynamic business needs.
            </p>
            <div className={style.languages}>
              <p className={style.language}>Azure DevOps</p>
              <p className={style.language}>Azure Portal</p>
              <p className={style.language}>App Service</p>
              <p className={style.language}>Database</p>
              <p className={style.language}>Functions</p>
              <p className={style.language}>CI/ CD</p>
              <p className={style.language}>Static Web App</p>
            </div>
          </div>

          <div className={style.item} id="exp-item3" data-active={false}>
            <div className={style.header}>
              <img alt="net core" src="/img/react.svg" className={style.icon} />
              <h2>Frontend Development</h2>
            </div>
            <p className={style.p}>
              Proficient in crafting responsive and dynamic user interfaces
              using modern frameworks like React and TypeScript, with a focus on
              performance and seamless user experiences.
            </p>
            <div className={style.languages}>
              <p className={style.language}>React</p>
              <p className={style.language}>TypeScript</p>
              <p className={style.language}>JavaScript</p>
              <p className={style.language}>HTML & CSS</p>
            </div>
          </div>

          <div
            className={style.item}
            style={{ alignSelf: "flex-start" }}
            id="exp-item4"
            data-active={false}
          >
            <div className={style.header}>
              <img alt="net core" src="/img/figma.svg" className={style.icon} />
              <h2>Figma Design</h2>
            </div>
            <p className={style.p}>
              Skilled in using Figma to develop creative and functional design
              solutions, emphasizing clarity, consistency, and user engagement.
            </p>
            <div className={style.languages}>
              <p className={style.language}>UI / UX</p>
              <p className={style.language}>Prototype</p>
            </div>
          </div>
          <div className={style.higlight} />
        </div>
      </div>
    </section>
  );
}
